import React, { useEffect } from "react";
import mapboxgl from "mapbox-gl";
import "./BaseMap.css";
// import ReactMapboxGl from "react-mapbox-gl";
// import MapboxWorker from "worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker";
const BaseMap = () => {
  // ReactMapboxGl.workerClass = MapboxWorker.default;
  mapboxgl.accessToken =
    "pk.eyJ1IjoicGFibG81OTYiLCJhIjoiY2tsaXBlYjdrMnBhMjJuczZoYWh4eTB1OCJ9.rtEGPWKEf1j8wAvYbsbURA";
  // "pk.eyJ1IjoicGFibG81OTYiLCJhIjoiY2p3dXZ5ZjZtMGp0cDQ5cGUwM2pyZGU5ZiJ9.1l9HEtbfNrsLYoUd0KJ7nA";
  useEffect(() => {
    const guatemalaLatLng = {
      lng: -89.8988087,
      lat: 15.33507,
    };
    let bounds = [
      [-93.8190457014214, 13.255335522883783], // Southwest coordinates
      [-86.43379047616702, 18.08019178399951], // Northeast coordinates
    ];
    const coberturaLocalidades = [
      {
        latLng: {
          lng: -90.0648909,
          lat: 14.852206,
        },
        title: "El Progreso",
        color: "2, 31, 161",
      },
      {
        latLng: {
          lng: -89.3959869,
          lat: 14.6891037,
        },
        title: "Chiquimula",
        color: "2, 31, 161",
      },
      {
        latLng: {
          lng: -88.5888038,
          lat: 15.7379098,
        },
        title: "Izabal",
        color: "2, 31, 161",
      },
      {
        latLng: {
          lng: -89.9390853,
          lat: 14.6507935,
        },
        title: "Jalapa",
        color: "2, 31, 161",
      },
      {
        latLng: {
          lng: -89.5180314,
          lat: 14.9661596,
        },
        title: "Zacapa",
        color: "2, 31, 161",
      },
      {
        latLng: {
          lng: -90.3139743,
          lat: 15.1009234,
        },
        title: "Baja Verapaz",
        color: "2, 31, 161",
      },
    ];
    const map = new mapboxgl.Map({
      container: "mapContainer",
      style: "mapbox://styles/pablo596/cklmhhlje3u5e17qmdie12nc0",
      center: [guatemalaLatLng.lng, guatemalaLatLng.lat],
      zoom: 8,
      attributionControl: false,
      maxBounds: bounds,
    });
    let size = 100;
    const pulsingDot = {
      width: size,
      height: size,
      data: new Uint8Array(size * size * 4),

      // get rendering context for the map canvas when layer is added to the map
      onAdd: function () {
        const canvas = document.createElement("canvas");
        canvas.width = this.width;
        canvas.height = this.height;
        this.context = canvas.getContext("2d");
      },

      // called once before every frame where the icon will be used
      render: function () {
        let duration = 1000;
        let t = (performance.now() % duration) / duration;

        let radius = (size / 2) * 0.3;
        let outerRadius = (size / 2) * 0.7 * t + radius;
        let context = this.context;

        // draw outer circle
        context.clearRect(0, 0, this.width, this.height);
        context.beginPath();
        context.arc(
          this.width / 2,
          this.height / 2,
          outerRadius,
          0,
          Math.PI * 2
        );
        context.fillStyle = "rgba(2, 31, 161," + (1 - t) + ")";
        context.fill();

        // draw inner circle
        context.beginPath();
        context.arc(this.width / 2, this.height / 2, radius, 0, Math.PI * 2);
        context.fillStyle = "rgba(2, 31, 161, 1)";
        context.strokeStyle = "white";
        context.lineWidth = 2 + 4 * (1 - t);
        context.fill();
        context.stroke();

        // update this image's data with data from the canvas
        this.data = context.getImageData(0, 0, this.width, this.height).data;

        // continuously repaint the map, resulting in the smooth animation of the dot
        map.triggerRepaint();

        // return `true` to let the map know that the image was updated
        return true;
      },
    };

    // create the marker
    let pointLocations = [];
    coberturaLocalidades.forEach((v, i) => {
      pointLocations.push({
        type: "Feature",
        geometry: {
          type: "Point",
          coordinates: [v.latLng.lng, v.latLng.lat],
        },
      });
      // let popup = new mapboxgl.Popup({}).setText(v.title);
      let el = document.createElement("div");
      el.id = "marker-" + i;
      new mapboxgl.Marker(el)
        .setLngLat([v.latLng.lng, v.latLng.lat])
        // .setPopup(popup) // sets a popup on this marker
        .addTo(map);

      // new mapboxgl.Popup({ offset: 15 })
      //   .setLngLat([v.latLng.lng, v.latLng.lat])
      //   .setHTML(v.title)
      //   .addTo(map);
    });

    console.log(pointLocations);
    map.on("load", function () {
      // map.scrollZoom.disable();
      map.addSource("cobertura", {
        type: "geojson",
        // data: "https://docs.mapbox.com/mapbox-gl-js/assets/earthquakes.geojson",
        data: "heatmap2.geojson",
      });
      map.addImage("pulsing-dot", pulsingDot, { pixelRatio: 2 });
      map.addLayer(
        {
          id: "cobertura-heat",
          type: "heatmap",
          source: "cobertura",
          maxzoom: 9,
          paint: {
            // Increase the heatmap weight based on frequency and property magnitude
            "heatmap-weight": [
              "interpolate",
              ["linear"],
              ["get", "familyMembers"],
              0,
              0,
              6,
              1,
            ],
            // Increase the heatmap color weight weight by zoom level
            // heatmap-intensity is a multiplier on top of heatmap-weight
            "heatmap-intensity": [
              "interpolate",
              ["linear"],
              ["zoom"],
              0,
              1,
              9,
              3,
            ],
            // Color ramp for heatmap.  Domain is 0 (low) to 1 (high).
            // Begin color ramp at 0-stop with a 0-transparancy color
            // to create a blur-like effect.
            "heatmap-color": [
              "interpolate",
              ["linear"],
              ["heatmap-density"],
              0,
              "rgba(33,102,172,0)",
              0.2,
              "rgb(103,169,207)",
              0.4,
              "rgb(209,229,240)",
              0.6,
              "rgb(253,219,199)",
              0.8,
              "rgb(239,138,98)",
              1,
              "rgb(178,24,43)",
            ],
            // Adjust the heatmap radius by zoom level
            "heatmap-radius": [
              "interpolate",
              ["linear"],
              ["zoom"],
              0,
              2,
              9,
              20,
            ],
            // Transition from heatmap to circle layer by zoom level
            "heatmap-opacity": [
              "interpolate",
              ["linear"],
              ["zoom"],
              7,
              1,
              9,
              0,
            ],
          },
        },
        "waterway-label"
      );

      map.addLayer(
        {
          id: "cobertura-point",
          type: "circle",
          source: "cobertura",
          minzoom: 7,
          paint: {
            // Size circle radius by earthquake magnitude and zoom level
            "circle-radius": [
              "interpolate",
              ["linear"],
              ["zoom"],
              7,
              ["interpolate", ["linear"], ["get", "familyMembers"], 1, 1, 6, 4],
              16,
              [
                "interpolate",
                ["linear"],
                ["get", "familyMembers"],
                1,
                5,
                6,
                50,
              ],
            ],
            // Color circle by earthquake magnitude
            "circle-color": [
              "interpolate",
              ["linear"],
              ["get", "familyMembers"],
              1,
              "rgba(33,102,172,0)",
              2,
              "rgb(103,169,207)",
              3,
              "rgb(209,229,240)",
              4,
              "rgb(253,219,199)",
              5,
              "rgb(239,138,98)",
              6,
              "rgb(178,24,43)",
            ],
            "circle-stroke-color": "grey",
            "circle-stroke-width": 1,
            // Transition from heatmap to circle layer by zoom level
            "circle-opacity": ["interpolate", ["linear"], ["zoom"], 7, 0, 8, 1],
          },
        },
        "waterway-label"
      );

      // map.addSource("points", {
      //   type: "geojson",
      //   data: {
      //     type: "FeatureCollection",
      //     features: pointLocations,
      //   },
      // });
      // map.addLayer({
      //   id: "points",
      //   type: "symbol",
      //   source: "points",
      //   layout: {
      //     "icon-image": "pulsing-dot",
      //   },
      // });

      // create DOM element for the marker

      // window.scrollTo(0, 0);
    });
  }, []);

  return <div id="mapContainer" className="map"></div>;
};

export default BaseMap;
