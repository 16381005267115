import "./App.css";
import Header from "./Header";
import Footer from "./Footer";
import ilustra_home from "./assets/images/ilustra_home.svg";
import info_image from "./assets/images/info_image.svg";
import rio_grande from "./assets/images/rio_grande.svg";
import teculutan from "./assets/images/teculutan.svg";
import progresiva from "./assets/images/progresiva.svg";
import location_icon from "./assets/icons/location_icon.svg";
import people_icon from "./assets/icons/people_icon.svg";
import radio_icon from "./assets/icons/radio_icon.svg";
import hospital from "./assets/icons/hospital.svg";
import meds from "./assets/icons/meds.svg";
import red_carpet from "./assets/icons/red-carpet.svg";
import shop from "./assets/icons/shop.svg";
import tractor from "./assets/icons/tractor.svg";
import MapContainer from "./GoogleMap";
import React, { Component } from "react";
import { Map, GoogleApiWrapper } from "google-maps-react";
import Chart from "react-google-charts";
import BaseMap from "./BaseMap";
import check from "./assets/icons/check-mark.svg";

function App() {
  return (
    <>
      <Header></Header>
      <main className="main">
        <section id="home">
          <div className="div__home">
            <div className="div__home__text">
              <h1 className="home__title">
                <span>Enlace Radial </span>
                <br />
                somos un grupo de emisoras ubicadas en el nororiente de
                Guatemala
              </h1>
              <p className="home__text">
                Nos dedicamos a servir a nuestras comunidades y a motivar a la
                industria y el comercio, contribuyendo al desarrollo
                socioeconomico de la región.
              </p>
              <div className="home__radio__links">
                <img src={rio_grande} />
                <img src={teculutan} />
                <img src={progresiva} />
              </div>
            </div>
            <img className="ilustra_home" src={ilustra_home} />
          </div>
        </section>
        <section id="rio_grande">
          <div className="div__rio_grande">
            <div className="div__rio_grande__cards">
              <div className="card__section">
                <img className="card__icon" src={location_icon} />
                <h3 className="card__title">Geográfico</h3>
                <p className="card__text">
                  Nuestra audiencia se encuentra ubicada principalmente en
                  fincas, caserios y aldeas del departamento del Progreso.
                  Lugares cicunvecionos (Jalapa, Zacapa, Baja Verapaz){" "}
                </p>
              </div>
              <div className="card__section">
                <img className="card__icon" src={people_icon} />
                <h3 className="card__title">Demográfico</h3>
                <p className="card__text">
                  Nuestro público consiste en hombres y mujeres de 18 a 50 años,
                  con gustos musicales tropicales, romanticas, rancheras y
                  banda.
                </p>
              </div>
              <div className="card__section">
                <img className="card__icon" src={radio_icon} />
                <h3 className="card__title">Alcance</h3>
                <p className="card__text">210,000 oyentes.</p>
              </div>
              <div className="card__section">
                <img src={rio_grande} />
              </div>
            </div>
            <button className="button__section">escúchala en vivo</button>
          </div>
        </section>
        <section id="progresiva">
          <div className="div__progresiva">
            <div className="div__progresiva__cards">
              <div className="card__section">
                <img className="card__icon" src={location_icon} />
                <h3 className="card__title">Geográfico</h3>
                <p className="card__text">
                  Nuestra audiencia se encuentra ubicada principalmente en
                  fincas, caserios y aldeas del departamento del Progreso.
                  Lugares cicunvecionos (Jalapa, Zacapa, Baja Verapaz){" "}
                </p>
              </div>
              <div className="card__section">
                <img className="card__icon" src={people_icon} />
                <h3 className="card__title">Demográfico</h3>
                <p className="card__text">
                  Nuestro público consiste en hombres y mujeres de 14 a 40 años,
                  con gustos musicales como romanticas, juveniles y tropicales.
                </p>
              </div>
              <div className="card__section">
                <img className="card__icon" src={radio_icon} />
                <h3 className="card__title">Alcance</h3>
                <p className="card__text">190,000 oyentes.</p>
              </div>
              <div className="card__section">
                <img src={progresiva} />
              </div>
            </div>
            <button className="button__section">escúchala en vivo</button>
          </div>
        </section>
        <section id="teculutan">
          <div className="div__teculutan">
            <div className="div__teculutan__cards">
              <div className="card__section">
                <img className="card__icon" src={location_icon} />
                <h3 className="card__title">Geográfico</h3>
                <p className="card__text">
                  Nuestra audiencia se encuentra ubicada principalmente en
                  fincas, caserios y aldeas del departamento de Zacapa. Lugares
                  cicunvecionos (Progreso, Chiquimula e Izabal){" "}
                </p>
              </div>
              <div className="card__section">
                <img className="card__icon" src={people_icon} />
                <h3 className="card__title">Demográfico</h3>
                <p className="card__text">
                  Nuestro público consiste en hombres y mujeres de 14 a 50 años,
                  con gustos musicales tropicales, romanticas, rancheras y banda
                  juvenil.
                </p>
              </div>
              <div className="card__section">
                <img className="card__icon" src={radio_icon} />
                <h3 className="card__title">Alcance</h3>
                <p className="card__text">380,000 oyentes.</p>
              </div>
              <div className="card__section">
                <img src={teculutan} />
              </div>
            </div>
            <button className="button__section">escúchala en vivo</button>
          </div>
        </section>
        <section id="info_empresa">
          <div className="div__info_empresa">
            <img className="ilustra_info_empresa" src={info_image} />
            <div className="div__info_empresa__text">
              <h1 className="info_empresa__title">
                {/* <span>Enlace Radial </span>
                <br/> */}
                ¡No importa el giro de tu empresa, Enlace Radial es tu mejor
                opción para aumentar tus ventas!
              </h1>
              <span className="info_empresa__text">
                Varias empresas han confiado en nosotros, dado a que nuestra
                capacidad de dar a conocer tu negocio es inigualable. Los casos
                de éxito que hemos tenido a lo largo del tiempo son negocios
                como:
                <ul>
                  <li>
                    <img src={shop} />
                    <p>Venta de electrodomesticos / tiendas</p>
                  </li>
                  <li>
                    <img src={meds} />
                    <p>Farmacias</p>
                  </li>
                  <li>
                    <img src={hospital} />
                    <p>Sanatorios</p>
                  </li>
                  <li>
                    <img src={tractor} />
                    <p>Agropecuarias</p>
                  </li>
                  <li>
                    <img src={red_carpet} />
                    <p>Eventos</p>
                  </li>
                </ul>
              </span>
            </div>
          </div>
        </section>
        <section id="cobertura">
          <div className="div__cobertura">
            <h1 className="cobertura__title">cobertura</h1>
            {/* <Map
              google={this.props.google}
              zoom={14}
              style={mapStyles}
              initialCenter={{
                lat: -1.2884,
                lng: 36.8233,
              }}
            /> */}
            <BaseMap />
          </div>
        </section>
        <section id="precios">
          <div className="div__precios">
            <h1 className="precios__title">precios y tarifas</h1>
            <div className="precios__cards">
              <div className="card">
                <h2 className="card__title">nota editorial</h2>
                <div className="card__text">
                  <ul>
                    <li>
                      Redacción de nota editorial con presencia en nuestras
                      redes sociales.
                    </li>
                  </ul>
                </div>
                <h2 className="card__price">q550.00</h2>
              </div>
              <div className="card">
                <h2 className="card__title">Paquete básico</h2>
                <div className="card__text">
                  <ul>
                    <li>1 nota editorial con promoción en redes sociales.</li>
                  </ul>
                </div>
                <h2 className="card__price">q990.00</h2>
              </div>
              <div className="card">
                <h2 className="card__title">Paquete empresarial</h2>
                <div className="card__text">
                  <ul>
                    <li>
                      1 nota editorial con promoción avanzada en redes sociales.
                    </li>
                    <li>1 banner en nuestra página web por 1 mes.</li>
                  </ul>
                </div>
                <h2 className="card__price">q1,670.00</h2>
              </div>
              <div className="card">
                <h2 className="card__title">Paquete premium</h2>
                <div className="card__text">
                  <ul>
                    <li>
                      1 nota editorial con promoción viral en redes sociales.
                    </li>
                    <li>1 banner en nuestra página web por 1 mes.</li>
                  </ul>
                </div>
                <h2 className="card__price">q2,800.00</h2>
              </div>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
}

const mapStyles = {
  width: "80%",
  height: "70%",
  margin: "auto",
  marginBottom: "15%",
};

// export class MapContainer extends Component {
//   render() {
//     console.log(this.props);
//     return (

//     );
//   }
// }

export default GoogleApiWrapper({
  apiKey: "AIzaSyDxXAtFpjzX8yFc0EVUr3KDJhsdLE0yr1Y",
})(App);

// export default App;
