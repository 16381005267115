import React from "react";
import "./Header.css";
import logo from "./assets/icons/logo.svg";
function Footer() {
  return (
    <footer className="footer" id="footer">
      <nav className="nav">
        <div className="nav__logo">
          <img src={logo} className="head__logo" />
          <span>ENLACE RADIAL</span>
        </div>
      </nav>
    </footer>
  );
}

export default Footer;
