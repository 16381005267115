import React, { useEffect, useState } from "react";
import logo from "./assets/icons/logo.svg";
import menu from "./assets/icons/menu.svg";
import right_arrow from "./assets/icons/right_arrow.svg";
import "./Header.css";

function Header() {
  const [visibleMenu, setvisibleMenu] = useState(false);

  const handleToggle = () => {
    setvisibleMenu(!visibleMenu);
    console.log(visibleMenu);
  };
  const closeMenu = () => {
    setvisibleMenu(false);
  };
  useEffect(() => {
    const header = document.getElementById("header");
    const sticky = header.offsetTop;
    const scrollCallBack = window.addEventListener("scroll", () => {
      if (window.pageYOffset > sticky) {
        header.classList.add("scroll__header");
      } else {
        header.classList.remove("scroll__header");
      }
    });
  }, []);
  return (
    <header className="header" id="header">
      <nav className="nav">
        <div className="nav__logo">
          <img src={logo} className="head__logo" />
          <span>
            ENLACE
            <br />
            RADIAL
          </span>
        </div>

        <ul className={`nav__menu ${visibleMenu ? "show__menu" : ""}`}>
          <div
            className={`back__button ${visibleMenu ? "show__back" : ""}`}
            onClick={handleToggle}
          >
            <img className="right__arrow" src={right_arrow} />
          </div>
          <li className="nav__item">
            <a className="nav__link active" href="/#home">
              nosotros
            </a>
          </li>
          <li className="nav__item">
            <a className="nav__link" href="/#rio_grande">
              río grande
            </a>
          </li>
          <li className="nav__item">
            <a className="nav__link" href="/#progresiva">
              progresiva
            </a>
          </li>
          <li className="nav__item">
            <a className="nav__link" href="/#teculutan">
              teculutan
            </a>
          </li>
          <li className="nav__item">
            <a className="nav__link" href="/#info_empresa">
              casos de éxito
            </a>
          </li>
          <li className="nav__item">
            <a className="nav__link" href="/#cobertura">
              cobertura
            </a>
          </li>
          <li className="nav__item">
            <a className="nav__link" href="/#precios">
              precios
            </a>
          </li>
          <img src={logo} className="menu__logo" />
        </ul>
      </nav>
      <div className="menu__button">
        <img
          id="menu__icon"
          className="menu__icon"
          src={menu}
          onClick={handleToggle}
        />
      </div>
    </header>
  );
}

export default Header;
