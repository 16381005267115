import React, { Component } from "react";
import { Map, GoogleApiWrapper } from "google-maps-react";

const mapStyles = {
  width: "80%",
  height: "70%",
  margin: "auto",
  marginBottom: "15%",
};

export class MapContainer extends Component {
  render() {
    console.log(this.props);
    return (
      <Map
        google={this.props.google}
        zoom={14}
        style={mapStyles}
        initialCenter={{
          lat: -1.2884,
          lng: 36.8233,
        }}
      />
    );
  }
}

export default GoogleApiWrapper({
  apiKey: "AIzaSyDxXAtFpjzX8yFc0EVUr3KDJhsdLE0yr1Y",
})(MapContainer);
